@font-face {
	font-family: 'nm';
	src: url('/font/nm.woff2?vn2wyi') format('woff2'),
		url('/font/nm.ttf?vn2wyi') format('truetype'),
		url('/font/nm.woff?vn2wyi') format('woff'),
		url('/font/nm.svg?vn2wyi#nm') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'nm' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	//line-height: 1.1;
	//width: 1em;
	text-align: center;
	display: inline-block;
	text-decoration: inherit;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon-genjutsu:before {
	content: "\e800";
}
.icon-home:before {
	content: "\e801";
}
.icon-map:before {
	content: "\e802";
}
.icon-chakra:before {
	content: "\e803";
}
.icon-friends:before {
	content: "\e804";
}
.icon-news:before {
	content: "\e805";
}
.icon-ninja-position:before {
	content: "\e806";
}
.icon-trophy:before {
	content: "\e807";
}
.icon-wind:before {
	content: "\e808";
}
.icon-hidden-leaf:before {
	content: "\e809";
}
.icon-neo-leaf:before {
	content: "\e80a";
}
.icon-seal:before {
	content: "\e80b";
}
.icon-hidden-sound:before {
	content: "\e80c";
}
.icon-hidden-cloud:before {
	content: "\e80d";
}
.icon-hidden-sand:before {
	content: "\e80e";
}
.icon-hidden-rain:before {
	content: "\e80f";
}
.icon-hidden-stone:before {
	content: "\e810";
}
.icon-rankings:before {
	content: "\e811";
}
.icon-hidden-mist:before {
	content: "\e812";
}
.icon-anbu-rebellion:before {
	content: "\e813";
}
.icon-frog:before {
	content: "\e814";
}
.icon-locked:before {
	content: "\e815";
}
.icon-material:before {
	content: "\e816";
}
.icon-ninja:before {
	content: "\e817";
}
.icon-pot:before {
	content: "\e818";
}
.icon-rank-award:before {
	content: "\e819";
}
.icon-receipt:before {
	content: "\e81a";
}
.icon-replay:before {
	content: "\e81b";
}
.icon-skull:before {
	content: "\e81c";
}
.icon-water:before {
	content: "\e81d";
}
.icon-back:before {
	content: "\e81e";
}
.icon-base:before {
	content: "\e81f";
}
.icon-book:before {
	content: "\e820";
}
.icon-brain:before {
	content: "\e821";
}
.icon-check:before {
	content: "\e822";
}
.icon-respect:before {
	content: "\e823";
}
.icon-coins:before {
	content: "\e824";
}
.icon-combo:before {
	content: "\e825";
}
.icon-diamond:before {
	content: "\e826";
}
.icon-dice:before {
	content: "\e827";
}
.icon-error:before {
	content: "\e828";
}
.icon-fire:before {
	content: "\e829";
}
.icon-heart:before {
	content: "\e82a";
}
.icon-inspect:before {
	content: "\e82b";
}
.icon-items:before {
	content: "\e82c";
}
.icon-lightning:before {
	content: "\e82d";
}
.icon-energy:before {
	content: "\e82e";
}
.icon-bloodline:before {
	content: "\e82f";
}
.icon-arena:before {
	content: "\e830";
}
.icon-akatsuki:before {
	content: "\e831";
}
.icon-clans:before {
	content: "\e832";
}
.icon-clans-empty:before {
	content: "\e833";
}
.icon-down-nerfed:before {
	content: "\e834";
}
.icon-earth:before {
	content: "\e835";
}
.icon-meteor:before {
	content: "\e836";
}
.icon-otsutsuki-village:before {
	content: "\e837";
}
.icon-rank-fox:before {
	content: "\e838";
}
.icon-rank-moon:before {
	content: "\e839";
}
.icon-re-shuriken:before {
	content: "\e83a";
}
.icon-shuriken:before {
	content: "\e83b";
}
.icon-sharingan:before {
	content: "\e83c";
}
.icon-rank-ribbon:before {
	content: "\e83d";
}
.icon-sunrise:before {
	content: "\e83e";
}
.icon-ability-scroll:before {
	content: "\e83f";
}
.icon-ribbon-band:before {
	content: "\e840";
}
.icon-check2:before {
	content: "\e841";
}
.icon-warning2:before {
	content: "\e842";
}
.icon-sword:before {
	content: "\e843";
}
.icon-arrow-right:before {
	content: "\e844";
}
.icon-arrow-left:before {
	content: "\e845";
}
.icon-caret-up:before {
	content: "\e846";
}
.icon-caret-down:before {
	content: "\e847";
}
.icon-ability-special:before {
	content: "\e848";
}
.icon-chest:before {
	content: "\e849";
}
.icon-crystal:before {
	content: "\e84a";
}
.icon-media-play:before {
	content: "\e84b";
}
.icon-media-fastforward:before {
	content: "\e84c";
}
.icon-media-first:before {
	content: "\e84d";
}
.icon-media-last:before {
	content: "\e84e";
}
.icon-media-pause:before {
	content: "\e84f";
}
.icon-media-stop:before {
	content: "\e850";
}
.icon-media-animations:before {
	content: "\e851";
}
.icon-calm:before {
	content: "\e852";
}
.icon-dharma:before {
	content: "\e853";
}
.icon-hand-eye:before {
	content: "\e854";
}
.icon-ninja-star:before {
	content: "\e855";
}
.icon-ninjutsu:before {
	content: "\e856";
}
.icon-oriental:before {
	content: "\e857";
}
.icon-pray:before {
	content: "\e858";
}
.icon-spa:before {
	content: "\e859";
}
.icon-stamina:before {
	content: "\e85a";
}
.icon-taijutsu:before {
	content: "\e85b";
}
.icon-uchiha:before {
	content: "\e85c";
}
.icon-martial-arts:before {
	content: "\e85d";
}
.icon-add:before {
	content: "\e85e";
}
.icon-subtract:before {
	content: "\e85f";
}
.icon-flask:before {
	content: "\e860";
}
.icon-ninjastar-1:before {
	content: "\e861";
}
.icon-panda:before {
	content: "\e862";
}
.icon-dragon-2:before {
	content: "\e863";
}
.icon-monster-1:before {
	content: "\e864";
}
.icon-monster-2:before {
	content: "\e865";
}
.icon-dragon-1:before {
	content: "\e866";
}
.icon-fan-1:before {
	content: "\e867";
}
.icon-katana-1:before {
	content: "\e868";
}
.icon-totem-1:before {
	content: "\e869";
}
.icon-helmet-1:before {
	content: "\e86a";
}
.icon-atom:before {
	content: "\e86b";
}
.icon-book-2:before {
	content: "\e86c";
}
.icon-book-3:before {
	content: "\e86d";
}
.icon-calculator:before {
	content: "\e86e";
}
.icon-clock:before {
	content: "\e86f";
}
.icon-close:before {
	content: "\e870";
}
.icon-close-2:before {
	content: "\e871";
}
.icon-comments:before {
	content: "\e872";
}
.icon-flask-2:before {
	content: "\e873";
}
.icon-flask-1:before {
	content: "\e874";
}
.icon-friend-add:before {
	content: "\e875";
}
.icon-gears:before {
	content: "\e876";
}
.icon-gears-2:before {
	content: "\e877";
}
.icon-geo-board:before {
	content: "\e878";
}
.icon-gift:before {
	content: "\e879";
}
.icon-graduation:before {
	content: "\e87a";
}
.icon-graph:before {
	content: "\e87b";
}
.icon-graph-2:before {
	content: "\e87c";
}
.icon-graph-3:before {
	content: "\e87d";
}
.icon-graph-4:before {
	content: "\e87e";
}
.icon-graph-5:before {
	content: "\e87f";
}
.icon-hourglass:before {
	content: "\e880";
}
.icon-info:before {
	content: "\e881";
}
.icon-leaf:before {
	content: "\e882";
}
.icon-map-point:before {
	content: "\e883";
}
.icon-masks:before {
	content: "\e884";
}
.icon-mount-myoboku:before {
	content: "\e885";
}
.icon-ninneko-fortress:before {
	content: "\e886";
}
.icon-paint:before {
	content: "\e887";
}
.icon-podium:before {
	content: "\e888";
}
.icon-questionmark:before {
	content: "\e889";
}
.icon-refresh:before {
	content: "\e88a";
}
.icon-search:before {
	content: "\e88b";
}
.icon-smarthpone:before {
	content: "\e88c";
}
.icon-sync:before {
	content: "\e891";
}
.icon-wrapping:before {
	content: "\e892";
}
.icon-molecule:before {
	content: "\e893";
}
.icon-arrow-down:before {
	content: "\e894";
}
.icon-arrow-up:before {
	content: "\e895";
}
.icon-flask-3:before {
	content: "\e896";
}
.icon-kage-hat:before {
	content: "\e897";
}
.icon-molecule-2:before {
	content: "\e898";
}
.icon-discord:before {
	content: "\e900";
}
.icon-wizard-staff:before {
	content: "\e901";
}
.icon-ocarina:before {
	content: "\e93a";
}
.icon-rune-sword:before {
	content: "\e939";
}
.icon-rune-stone:before {
	content: "\e938";
}
.icon-orb-wand:before {
	content: "\e937";
}
.icon-pill:before {
	content: "\e936";
}
.icon-shoulder-armor:before {
	content: "\e935";
}
.icon-lamellar:before {
	content: "\e934";
}
.icon-breastplate:before {
	content: "\e933";
}
.icon-leather-vest:before {
	content: "\e932";
}
.icon-metal-hand:before {
	content: "\e931";
}
.icon-double-ringed-orb:before {
	content: "\e930";
}
.icon-leg-armor:before {
	content: "\e92f";
}
.icon-layered-armor:before {
	content: "\e92e";
}
.icon-kimono:before {
	content: "\e92d";
}
.icon-brutal-helm:before {
	content: "\e92c";
}
.icon-elf-helmet:before {
	content: "\e92b";
}
.icon-black-knight-helm:before {
	content: "\e92a";
}
.icon-overlord-helm:before {
	content: "\e929";
}
.icon-spartan-helmet:before {
	content: "\e928";
}
.icon-viking-helmet:before {
	content: "\e927";
}
.icon-thrown-daggers:before {
	content: "\e926";
}
.icon-trefoil-shuriken:before {
	content: "\e925";
}
.icon-shuriken-2:before {
	content: "\e924";
}
.icon-halberd-shuriken:before {
	content: "\e923";
}
.icon-ninja-star-2:before {
	content: "\e921";
}
.icon-sai:before {
	content: "\e922";
}
.icon-bordered-shield:before {
	content: "\e920";
}
.icon-shoulder-scales:before {
	content: "\e91f";
}
.icon-mailed-fist:before {
	content: "\e91e";
}
.icon-backbone-shell:before {
	content: "\e91d";
}
.icon-fishbone:before {
	content: "\e91c";
}
.icon-blood:before {
	content: "\e91b";
}
.icon-dripping-knife:before {
	content: "\e91a";
}
.icon-spiked-mace:before {
	content: "\e919";
}
.icon-scythe:before {
	content: "\e918";
}
.icon-battle-axe:before {
	content: "\e917";
}
.icon-thor-hammer:before {
	content: "\e916";
}
.icon-broadhead-arrow:before {
	content: "\e915";
}
.icon-high-shot:before {
	content: "\e914";
}
.icon-gavel:before {
	content: "\e913";
}
.icon-crossbow:before {
	content: "\e912";
}
.icon-templar-shield:before {
	content: "\e911";
}
.icon-spiked-snail:before {
	content: "\e910";
}
.icon-steel-claws:before {
	content: "\e90f";
}
.icon-slashed-shield:before {
	content: "\e90e";
}
.icon-pearl-necklace:before {
	content: "\e90d";
}
.icon-emerald:before {
	content: "\e90c";
}
.icon-topaz:before {
	content: "\e90b";
}
.icon-rupee:before {
	content: "\e90a";
}
.icon-sapphire:before {
	content: "\e909";
}
.icon-crystal-growth:before {
	content: "\e908";
}
.icon-crown:before {
	content: "\e907";
}
.icon-jeweled-chalice:before {
	content: "\e906";
}
.icon-amethyst:before {
	content: "\e905";
}
.icon-gem-necklace:before {
	content: "\e904";
}
.icon-bleeding-eye:before {
	content: "\e903";
}
.icon-whirlwind:before {
	content: "\e902";
}
.icon-triforce:before {
	content: "\e93b";
}
.icon-documents:before {
	content: "\e93c";
}
.icon-foam:before {
	content: "\e93d";
}
.icon-dripping-goo:before {
	content: "\e93e";
}
.icon-plain-dagger:before {
	content: "\e93f";
}
.icon-meat-cleaver:before {
	content: "\e940";
}
.icon-cracked-saber:before {
	content: "\e941";
}
.icon-flail:before {
	content: "\e942";
}
.icon-thrown-knife:before {
	content: "\e943";
}
.icon-tied-scroll:before {
	content: "\e944";
}
.icon-scroll-unfurled:before {
	content: "\e945";
}
.icon-wax-tablet:before {
	content: "\e946";
}
.icon-stone-pile:before {
	content: "\e947";
}
.icon-stone-sphere:before {
	content: "\e948";
}
.icon-fossil:before {
	content: "\e949";
}
.icon-ammonite-fossil:before {
	content: "\e94a";
}
.icon-curvy-knife:before {
	content: "\e94b";
}
.icon-steampunk-goggles:before {
	content: "\e94c";
}
.icon-cracked-mask:before {
	content: "\e94d";
}
.icon-hood:before {
	content: "\e94e";
}
.icon-tribal-mask:before {
	content: "\e94f";
}
.icon-walking-boot:before {
	content: "\e950";
}
.icon-leather-boot:before {
	content: "\e951";
}
.icon-wingfoot:before {
	content: "\e952";
}
.icon-wing-cloak:before {
	content: "\e953";
}
.icon-bow-tie-ribbon:before {
	content: "\e954";
}
.icon-robe:before {
	content: "\e955";
}
.icon-cape:before {
	content: "\e956";
}
.icon-striped-sword:before {
	content: "\e957";
}
.icon-sparkling-sabre:before {
	content: "\e958";
}
.icon-crossed-swords:before {
	content: "\e959";
}
.icon-stone-axe:before {
	content: "\e95a";
}
.icon-skull-staff:before {
	content: "\e95b";
}
.icon-barbed-spear:before {
	content: "\e95c";
}
.icon-halberd:before {
	content: "\e95d";
}
.icon-sickle:before {
	content: "\e95e";
}
.icon-bloody-sword:before {
	content: "\e95f";
}
.icon-fragmented-sword:before {
	content: "\e960";
}
.icon-knife-thrust:before {
	content: "\e961";
}
.icon-bo:before {
	content: "\e963";
}
.icon-harpoon-trident:before {
	content: "\e964";
}
.icon-spine-arrow:before {
	content: "\e965";
}
.icon-barbed-arrow:before {
	content: "\e966";
}
.icon-magic-trident:before {
	content: "\e967";
}
.icon-spotted-arrowhead:before {
	content: "\e968";
}
.icon-cursed-seal:before {
	content: "\e969";
}
.icon-kunai:before {
	content: "\e962";
}
.icon-metal-disc:before {
	content: "\e96a";
}
.icon-dragon-head:before {
	content: "\e96b";
}
.icon-fire-bottle:before {
	content: "\e96c";
}
.icon-handheld-fan:before {
	content: "\e96d";
}
.icon-dodging:before {
	content: "\e96e";
}
.icon-alien-egg:before {
	content: "\e96f";
}
.icon-spiral-bottle:before {
	content: "\e970";
}
.icon-spinal-coil:before {
	content: "\e971";
}
.icon-beanstalk:before {
	content: "\e972";
}
.icon-curled-tentacle:before {
	content: "\e973";
}
.icon-person:before {
	content: "\e974";
}
.icon-lineup:before {
	content: "\e975";
}
.icon-formation:before {
	content: "\e976";
}
.icon-puppet:before {
	content: "\e977";
}
.icon-anvil:before {
	content: "\e978";
}
.icon-spider-bot:before {
	content: "\e979";
}
.icon-blunderbuss:before {
	content: "\e97a";
}
.icon-blackball:before {
	content: "\e97b";
}
.icon-robot-golem:before {
	content: "\e97c";
}
.icon-lyre:before {
	content: "\e97d";
}
.icon-phrygian-cap:before {
	content: "\e97e";
}
.icon-pointy-hat:before {
	content: "\e97f";
}
.icon-whirlpool-shuriken:before {
	content: "\e980";
}
.icon-ninja-armor:before {
	content: "\e981";
}
.icon-belt-armor:before {
	content: "\e982";
}
.icon-lotus-flower:before {
	content: "\e983";
}
.icon-triple-claws:before {
	content: "\e984";
}
.icon-tabi-boot:before {
	content: "\e985";
}
.icon-ring:before {
	content: "\e986";
}
.icon-heart-organ:before {
	content: "\e987";
}
.icon-card-ace-diamonds:before {
	content: "\e988";
}
.icon-mangekyo-itachi:before {
	content: "\e99c";
}
.icon-byakugan:before {
	content: "\e99b";
}
.icon-sharingan-3-tomoe2:before {
	content: "\e99a";
}
.icon-eye-circle:before {
	content: "\e999";
}
.icon-rinnegan:before {
	content: "\e998";
}
.icon-mangekyo-obito:before {
	content: "\e997";
}
.icon-rinne-sharingan:before {
	content: "\e996";
}
.icon-mangekyo-izuna:before {
	content: "\e995";
}
.icon-mangekyo-sasuke:before {
	content: "\e994";
}
.icon-mangekyo-shisui:before {
	content: "\e993";
}
.icon-mangekyo-indra:before {
	content: "\e992";
}
.icon-mangekyo-shin:before {
	content: "\e991";
}
.icon-eternal-mangekyo:before {
	content: "\e990";
}
.icon-senjutsu-eye:before {
	content: "\e98f";
}
.icon-kyubi-eye:before {
	content: "\e98e";
}
.icon-key:before {
	content: "\e98c";
}
.icon-triton-head:before {
	content: "\e98b";
}
.icon-bone-joint:before {
	content: "\e98a";
}
.icon-ice-spear:before {
	content: "\e989";
}
.icon-filled-circle:before {
	content: "\e98d";
}
.icon-dna:before {
	content: "\e99d";
}
.icon-bull-horns:before {
	content: "\e99e";
}
.icon-saiyan-suit:before {
	content: "\e99f";
}
.icon-root-tip:before {
	content: "\e9a0";
}
.icon-mangekyo-madara:before {
	content: "\e9a1";
}
.icon-help:before {
	content: "\e9a2";
}
.icon-rank-1:before {
	content: "\e9a3";
}
.icon-rank-2:before {
	content: "\e9a4";
}
.icon-rank-3:before {
	content: "\e9a5";
}
.icon-round-info-button:before {
	content: "\e9a6";
}
.icon-circle-filled:before {
	content: "\e9a7";
}
.icon-battery:before {
	content: "\e9a8";
}
.icon-uchiha-clan:before {
	content: "\e9a9";
}
.icon-burning-skull:before {
	content: "\e9aa";
}
.icon-shield:before {
	content: "\e9ab";
}
.icon-swords-emblem:before {
	content: "\e9ac";
}
.icon-castle:before {
	content: "\e9ad";
}
.icon-traffic-cone:before {
	content: "\e9ae";
}
.icon-winged-shield:before {
	content: "\e9af";
}
.icon-white-tower:before {
	content: "\e9b0";
}
.icon-boiling-bubbles:before {
	content: "\e9b1";
}
.icon-ghost:before {
	content: "\e9b2";
}
.icon-screen-impact:before {
	content: "\e9b3";
}
.icon-two-coins:before {
	content: "\e9b4";
}
.icon-diamond-hilt:before {
	content: "\e9b5";
}
.icon-broadsword:before {
	content: "\e9b6";
}
.icon-spear:before {
	content: "\e9b7";
}
.icon-rod-of-asclepius:before {
	content: "\e9b8";
}
.icon-ankh:before {
	content: "\e9b9";
}
.icon-double-shot:before {
	content: "\e9ba";
}
.icon-sagittarius:before {
	content: "\e9bb";
}
.icon-holy-symbol:before {
	content: "\e9bc";
}
.icon-pagoda:before {
	content: "\e9bd";
}
.icon-horn-internal:before {
	content: "\e9be";
}
.icon-ryuichi-temple:before {
	content: "\e9bf";
}